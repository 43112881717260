<template>
  <div class="app-wrapper">
    <router-view />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',

  components: {
  },

});
</script>

<style>
.app-wrapper {
  overflow: hidden;
}
</style>
